@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}

@font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat/Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat/SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat/Medium.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat/Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@layer components {
    /** Desktop, only one per page, highest level **/
    .headline1 {
        font-family: "Montserrat";
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 2.9rem;
    }
    .headline2 {
        font-family: "Montserrat";
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.6rem;
    }
    /** Mobile main headline **/
    .headline3 {
        font-family: "Montserrat";
        font-size: 1.74rem;
        font-weight: 700;
        line-height: 2.3rem;
    }
    .headline4 {
        font-family: "Montserrat";
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
    }
    .headline5 {
        font-family: "Montserrat";
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.5rem;
    }
    /** Desktop large body **/
    .body1 {
        font-family: "Montserrat";
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
    /** Desktop and mobile default body **/
    .body2 {
        font-family: "Montserrat";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3rem;
    }
    /** Help text **/
    .body3 {
        font-family: "Montserrat";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
    }
    .link1 {
        font-family: "Montserrat";
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
    .link2 {
        font-family: "Montserrat";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3rem;
    }
    .link3 {
        font-family: "Montserrat";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.1rem;
    }
    .body1bold {
        font-family: "Montserrat";
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.5rem;
    }
    .body2bold {
        font-family: "Montserrat";
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.3rem;
    }
    .body3bold {
        font-family: "Montserrat";
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.1rem;
    }
    /** Desktop label **/
    .label1 {
        font-family: "Montserrat";
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.5rem;
    }
    /** Mobile label **/
    .label2 {
        font-family: "Montserrat";
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.3rem;
    }
    /** Global Navigation Tabs; all caps **/
    .tabLabel {
        font-family: "Montserrat";
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.3rem;
    }
}

.food-select-parent-div .ml-10 input{
    vertical-align: text-bottom;
}

.food-select-parent-div .h-4{
    display: none;
}
.food-select-parent-div .ml-10 {
    margin-left: 1.5rem !important;
}

.grid_hphv2{
    display: grid;
  }

  .grid_v2{
    display: flex;
    flex-direction: column;
  }
  
  @media only screen and (min-width: 1024px) {
    .grid_v2{
        display: grid;
      }
  }

@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
}

@keyframes slideOutToTop {
0% {
    transform: translateY(0);
}
100% {
    transform: translateY(-100%);
}
}
  
.slide-in {
animation: slideInFromTop 0.5s ease-out forwards;
}

.slide-out {
animation: slideOutToTop 0.5s ease-in forwards;
}

.offset-outline:focus{
    position: relative;
    outline: none;
}

.offset-outline:focus::before,
.offset-outline:focus::after{
    content: '';
    position: absolute;
    border-radius: 5px;
    outline: 1px solid #0054A4;
}

.offset-outline:focus::before{
    top: 0;
    bottom: 0;
    left: -4px;
    right: -4px;
    pointer-events: none;
}

.offset-outline:focus::after{
    top: 0;
    bottom: 0;
    left: -4px;
    right: -4px;
    pointer-events: none;
}

  